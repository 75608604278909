<template>
  <div class="online-setting fd-w-full fd-h-full fd-bg-white fd-px-4 fd-py-6">
    <div class="fd-w-full fd-flex fd-items-center fd-justify-start fd-mb-4">
      <span class="fd-text-lg fd-font-semibold fd-text-theme-2">{{ $t('onlineSetting.onlineSetting') }}</span>
    </div>

    <div class="fd-px-6 fd-py-4">
      <span class="fd-text-lg fd-font-bold fd-text-theme-6">{{ $t('global.languages') }}</span>

      <div class="fd-mt-6">
        <b-tabs fill>
          <b-tab
            v-for="(language, index) in languages"
            :key="index"
          >
            <template #title>
              <div class="fd-flex fd-items-center fd-justify-center fd-pt-2">
                <span class="fd-block">{{ language.name.charAt(0).toUpperCase() + language.name.slice(1) }}</span>
                <span v-if="language.required" class="fd-block fd--mt-2 fd-text-red-500">*</span>
              </div>
            </template>

            <div class="fd-pt-12">
              <b-form-group
                :label="$t('form.title')"
                label-for="title"
              >
                <b-form-input
                  id="title"
                  :placeholder="$t('form.title')"
                  v-model="language.title"
                />
              </b-form-group>

              <b-form-group
                :label="$t('form.description')"
                label-for="description"
                class="fd-mt-8"
              >
                <b-form-textarea
                  id="description"
                  :placeholder="$t('form.description')"
                  rows="3"
                  v-model="language.description"
                />
              </b-form-group>

              <b-form-group
                :label="$t('form.aboutUs')"
                label-for="aboutUs"
                class="fd-mt-8"
              >
                <b-form-textarea
                  id="aboutUs"
                  :placeholder="$t('form.aboutUs')"
                  rows="10"
                  v-model="language.about_us"
                />
              </b-form-group>

              <div
                v-if="language.code !== 'tr'"
                class="fd-flex fd-items-end fd-cursor-pointer fd-mt-8"
                @click="autoTranslate(language)"
              >
                <SvgLoader :name="'translateIcon'"/>
                <span class="fd-block fd-ml-1 fd-text-theme-4 underline">{{ $t('onlineSetting.autoTranslate') }}</span>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>

    <div class="fd-w-full fd-flex fd-items-start fd-justify-end fd-mt-8">
      <b-button
        @click="save"
        variant="primary"
        style="padding-left: 35px !important; padding-right: 35px !important;"
      >
        {{ $t('global.save') }}
      </b-button>
    </div>
    <b-toast id="example-toast" title="BootstrapVue" static></b-toast>
  </div>
</template>

<script>
import { BFormInput, BTabs, BTab, BFormGroup, BFormTextarea, BButton, BToast, BFormCheckbox } from 'bootstrap-vue'
import {mapGetters} from "vuex";
import toast from "@/mixins/toast";

export default {
  name: "Description",
  components: {BFormGroup, BFormInput, BFormTextarea, BTabs, BTab, BButton, BToast, BFormCheckbox},
  mixins: [toast],
  data() {
    return {
      validated: false,
      description: {
        beginning_from: '',
        completed_in: '',
        compilation_percentage: '',
        land_area: '',
        payment: '',
      },
      languages: [
        {
          id: 1,
          name: 'Turkish',
          code: 'tr',
          required: true,
          title: '',
          description: '',
          about_us: '',
        },
        {
          id: 2,
          name: 'English',
          code: 'en',
          required: false,
          title: '',
          description: '',
          about_us: '',
        },
        {
          id: 3,
          name: 'Persian',
          code: 'fa',
          required: false,
          title: '',
          description: '',
          about_us: '',
        },
        {
          id: 4,
          name: 'Arabic',
          code: 'ar',
          required: false,
          title: '',
          description: '',
          about_us: '',
        },
        {
          id: 5,
          name: 'Russian',
          code: 'ru',
          required: false,
          title: '',
          description: '',
          about_us: '',
        }
      ]
    }
  },
  created() {
    this.$store.dispatch('project/getLanguages', this.$route.params.id)
      .then(() => {
        this.dataPreparation()
      })
  },
  methods: {
    async save() {
      await this.checkLanguagesRequired()
      if (!this.validated) {
        this.makeToast(this.$t('errors.turkishLanguageIsRequired'), this.$t('global.error'), 'BellIcon', 'danger')
        return;
      }

      let formData = new FormData()
      const languages = this.getLanguagesValues()
      formData.append('languages', JSON.stringify(languages))
      this.$store.dispatch('project/updateLanguages', {
        id: this.$route.params.id,
        data: formData
      }).then(response => {
        this.makeToast(response.data.message)
      })
    },
    async autoTranslate(language) {
      await this.checkLanguagesRequired()
      if (!this.validated) {
        this.makeToast(this.$t('errors.turkishLanguageIsRequired'), this.$t('global.error'), 'BellIcon', 'danger')
        return;
      }
      const turkish = this.languages.find(item => item.code === 'tr')
      this.$store.dispatch('project/updateOnlineSettingAutoTranslate', {
        type: 'projects',
        data: {
          code: language.code,
          title: turkish.title,
          description: turkish.description,
          about_us : turkish.about_us,
        }
      })
        .then(response => {
          this.languages.forEach((item, index) => {
            if (item.code === language.code) {
              this.languages[index].title = response.data.result.title
              this.languages[index].description = response.data.result.description
              this.languages[index].about_us = response.data.result.about_us
            }
          })
        })
    },
    checkLanguagesRequired() {
      return this.languages.forEach(item => {
        if (!item.required) return

        this.validated = !!(item.title || item.description || item.about_us);
      })
    },
    dataPreparation() {
      const languages = this.$store.getters["project/languages"]

      this.languages.forEach(item => {

        const lang = languages.find(lang => {
          return lang.code.toLowerCase() === item.code.toLowerCase()
        })
        if (!lang) return

        item.title = lang.title
        item.description = lang.description
        item.about_us = lang.about_us
      })
    },
    getLanguagesValues() {
      return this.languages.map(item => {
        item = {
          code: item.code,
          title: item.title,
          description: item.description,
          about_us: item.about_us,
        }
        return item
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.bg-section {
  background: rgba(0, 0, 0, 0.0001);
  box-shadow: 0 4px 24px rgba(40, 74, 51, 0.06);
  border-radius: 6px;
}
::v-deep .feature--items {
  & > div {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
  }
}
</style>